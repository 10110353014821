import * as React from "react"

import { cn } from "../lib/utils"

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    editing?: boolean
  }

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, editing, ...props }, ref) => (
    <div className={cn(
      "border-input overflow-hidden rounded-md border w-full h-full",
      editing ? 'border-foreground' : '',
      className,
    )}
    >
      <textarea
        className={cn(
          "flex min-h-[80px] w-full bg-background overflow-y-scroll w-full rounded-md border border-input disabled:bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed",
          editing ? 'bg-card border-none' : 'bg-background',
        )}
        ref={ref}
        {...props}
      />
    </div>
  ),
)
Textarea.displayName = "Textarea"

export { Textarea }
