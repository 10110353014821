
import { useState } from "react";
import { useBoolean } from 'usehooks-ts';
import DatePicker from "react-datepicker";
import DropdownMenu from '../dropdownMenu';
import useNotification from "../../Common/Hooks/useNotification";
import { parseISO } from 'date-fns';


interface Props<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    isSubMenu?: boolean;
    Icon?: React.ComponentType<any>;
    onDateChange: Function;
    dateRange: any
}

function DataTableCalendarFilter<TData, TValue>({
    title,
    Icon,
    onDateChange,
    dateRange
}: Props<TData, TValue>) {
    const isSearchOpen = useBoolean();
    const notifications = useNotification();

    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setDate(today.getDate() - 30);

    const [endDate, setEndDate] = useState(parseISO(dateRange.end));
    const [startDate, setStartDate] = useState(parseISO(dateRange.start));
    const [fullDate, setFullDate] = useState({ start: today, end: oneMonthAgo, userSelected: false });

    const handleDateStart = (val) => {
        setStartDate(val);
        setFullDate({ start: val, end: endDate, userSelected: true });
    };
    const handleDateEnd = (val) => {
        setEndDate(val);
        setFullDate({ start: startDate, end: val, userSelected: true });
    };

    const isWithin120Days = (startDate, endDate) => {
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const millisecondsIn120Days = 24 * 60 * 60 * 1000 * 120;

        return differenceInTime <= millisecondsIn120Days;
    }
    const getDate = () => {
        const valid120Range = isWithin120Days(startDate || today, endDate || oneMonthAgo);

        if (!startDate) {
            notifications.addToast({ type: 'error', message: 'Must select a start date.' });
            return;
        } else if (!endDate) {
            notifications.addToast({ type: 'error', message: 'Must select an end date.' });
            return;
        } else if (endDate <= startDate) {
            notifications.addToast({ type: 'error', message: 'End date must be later than start date.' });
            return;
        } else if (!valid120Range) {
            notifications.addToast({ type: 'error', message: 'Selected dates exceed a range of 120 days.' });
            return;
        } else {
            onDateChange(fullDate);
        }
    };

    return (
        <>
            <DropdownMenu.Sub open={isSearchOpen.value} onOpenChange={isSearchOpen.setValue}>
                <DropdownMenu.SubTrigger>
                    {Icon && <Icon className="mr-2 h-4 w-4" />}
                    Search {title}
                </DropdownMenu.SubTrigger>
                <DropdownMenu.Portal>
                    <DropdownMenu.SubContent>
                        <div className="filter-datepickerContainer">
                            <div className="datePicker-wrapper">
                                <p>Start:</p>
                                <div className="datePicker" style={{ border: " 1px solid #ccc", borderRadius: "5px", }}>
                                    <DatePicker
                                        onChange={handleDateStart}
                                        selected={startDate}
                                        maxDate={today}
                                        dateFormat="MMM d, yyyy"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="filter-datepickerContainer">
                            <div className="datePicker-wrapper">
                                <p>End:</p>
                                <div className="datePicker" style={{ border: " 1px solid #ccc", borderRadius: "5px", }}>
                                    <DatePicker
                                        onChange={handleDateEnd}
                                        selected={endDate}
                                        minDate={startDate}
                                        maxDate={today}
                                        dateFormat="MMM d, yyyy"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="button sm primary" style={{ marginLeft: '0px' }} onClick={getDate}>Submit</div>
                    </DropdownMenu.SubContent>
                </DropdownMenu.Portal>
            </DropdownMenu.Sub>
        </>
    )
}
export default DataTableCalendarFilter;
