import React, {useState, useEffect} from 'react';
import DropdownMenu from '../../../ui/dropdownMenu';
import { Button } from '../../../ui/button';
import { AppWindowIcon, ContainerIcon, FlaskConicalIcon, LayersIcon, PlusIcon, UserIcon, Wrench, CalendarRange } from 'lucide-react';
import { Table } from '@tanstack/react-table';
import { DecisionRecord } from '../DecisionRecordType';
import _ from 'lodash';
import { createPortal } from 'react-dom';
import DataTableDropdownFilter from '../../../ui/dataTable/dataTableDropdownFilter';
import DataTableDropdownSelectedFilters from '../../../ui/dataTable/dataTableDropdownSelectedFilters';
import DataTableSearchFilter from '../../../ui/dataTable/dataTableSearchFilter';
import DataTableSearchSelectedFilters from '../../../ui/dataTable/dataTableSearchSelectedFilters';
import { doRequest } from '../../../Utils/utils';
import DataTableCalendarFilter from '../../../ui/dataTable/dataTableCalendarFilter';
import DataTableCalendarSelectedFilter from '../../../ui/dataTable/dataTableCalendarSelectedFilter';

type DecisionRecordsDataTableFiltersProps = {
  table: Table<DecisionRecord>;
  portalRef: React.RefObject<HTMLDivElement> | null;
  onDateChange:Function;
  dateRange:any;
};


function DecisionRecordsDataTableFilters({ table, portalRef, onDateChange, dateRange }: DecisionRecordsDataTableFiltersProps) {

  const managerColumn = table.getColumn('Manager');
  const srManagerColumn = table.getColumn('SrManager');
  const directorColumn = table.getColumn('Director');
  const vpColumn = table.getColumn('VP');

  const testNameColumn = table.getColumn('Test Name');
  const applicationColumn = table.getColumn('Application');
  const tierColumn = table.getColumn('SLATier');
  const environemntColumn = table.getColumn('Environment');
  const toolColumn = table.getColumn('TestTool');

  const [tools, setTools] = useState<string[]>([]);
  useEffect(() => {
    doRequest('/api/v1/testtools', 'get', null, null)
      .then(({ data }) => {
        setTools(data.map((tool: any) => tool.name));
      })
      .catch((err) => {
        console.error(err);
      })
  }, [])

  return (
    <>
      {portalRef?.current
      && createPortal(
        <>
          {managerColumn && <DataTableDropdownSelectedFilters column={managerColumn} title="Managers" />}
          {srManagerColumn && <DataTableDropdownSelectedFilters column={srManagerColumn} title="Sr Managers" />}
          {directorColumn && <DataTableDropdownSelectedFilters column={directorColumn} title="Directors" />}
          {vpColumn && <DataTableDropdownSelectedFilters column={vpColumn} title="VPs" />}
          {testNameColumn && <DataTableSearchSelectedFilters column={testNameColumn} title="Test Name" />}
          {applicationColumn && <DataTableSearchSelectedFilters column={applicationColumn} title="Application" />}
          {tierColumn && <DataTableDropdownSelectedFilters column={tierColumn} title="SLA Tier" />}
          {environemntColumn && <DataTableSearchSelectedFilters column={environemntColumn} title="Environment" />}
          {toolColumn && <DataTableDropdownSelectedFilters column={toolColumn} title="Test Tool" />}
          {<DataTableCalendarSelectedFilter title="Created At" dateRange={dateRange} onDateChange={onDateChange} />}
        </>,
        portalRef.current,
      )}
      {/* {managerColumn && (
        <DataTableDropdownFilter table={table} column={managerColumn} title="Managers" Icon={UserIcon} />
      )} */}
      <DropdownMenu>
        <DropdownMenu.Trigger className="m-0" asChild>
          <Button variant="outline" className="h-8 gap-2">
            <p>Filters</p>
            <PlusIcon className="h-4 w-4 text-primary" />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="w-56">
          <DropdownMenu.Label>Users</DropdownMenu.Label>
          <DropdownMenu.Group>
            {managerColumn && (
              <DataTableDropdownFilter
                table={table}
                column={managerColumn}
                title="Managers"
                Icon={UserIcon}
                isSubMenu
              />
            )}
            {srManagerColumn && (
              <DataTableDropdownFilter
                table={table}
                column={srManagerColumn}
                title="Sr Managers"
                Icon={UserIcon}
                isSubMenu
              />
            )}
            {directorColumn && (
              <DataTableDropdownFilter
                table={table}
                column={directorColumn}
                title="Directors"
                Icon={UserIcon}
                isSubMenu
              />
            )}
            {vpColumn && (
              <DataTableDropdownFilter table={table} column={vpColumn} title="VPs" Icon={UserIcon} isSubMenu />
            )}
            {tierColumn && (
              <DataTableDropdownFilter
                table={table}
                column={tierColumn}
                title="Tiers"
                Icon={LayersIcon}
                isSubMenu
                presets={['Diamond', 'Platinum', 'Gold', 'Silver', 'Bronze']}
              />
            )}
            {toolColumn && (
              <DataTableDropdownFilter
                table={table}
                column={toolColumn}
                title="Tools"
                Icon={Wrench}
                isSubMenu
                presets={tools}
              />
            )}
          </DropdownMenu.Group>
          <DropdownMenu.Separator />
          <DropdownMenu.Label>Searches</DropdownMenu.Label>
          <DropdownMenu.Group>
            {testNameColumn && (
              <DataTableSearchFilter column={testNameColumn} title="Test Names" Icon={FlaskConicalIcon} isSubMenu />
            )}
            {applicationColumn && (
              <DataTableSearchFilter column={applicationColumn} title="Applications" Icon={AppWindowIcon} isSubMenu />
            )}
            {environemntColumn && (
              <DataTableSearchFilter column={environemntColumn} title="Environments" Icon={ContainerIcon} isSubMenu />
            )}
            
            <DataTableCalendarFilter title="Created At" onDateChange={onDateChange} Icon={CalendarRange} dateRange={dateRange}/>
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu>
    </>
  );
}

export default DecisionRecordsDataTableFilters;
