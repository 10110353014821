import React, { useEffect, useState } from 'react';
import { NavLink, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from 'react-loading';
import { FieldValues, useForm } from 'react-hook-form';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import Card from '../../ui/card';
import { doRequest } from '../../Utils/utils';
import { Button } from '../../ui/button';
import Form, { FormEntry } from '../../ui/form';
import { Textarea } from '../../ui/textarea';
import { TestRunRecord } from './TestRunRecordType';
import SidebarSectionRow from '../../Layout/Sidebar/SidebarSectionRow';
import SidebarSection from '../../Layout/Sidebar/SidebarSection';
import { useProfileContext } from '../../Utils/user';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import UserSelector from '../../Layout/Sidebar/UserSelector';
import AttachmentsView from '../../Layout/AttachmentsView/AttachmentsView';
import NotFound from '../../SharedComponents/NotFound';
import Dialog from '../../ui/dialog';
import DropdownMenu from '../../ui/dropdownMenu';
import Select from '../../ui/select';
import Popover from '../../ui/popover';
import { Calendar } from '../../ui/calendar';
import { cn } from '../../lib/utils';

const formEntries: FormEntry[] = [
  {
    Name: 'Status',
    Label: 'Status',
    InputType: Select,
    SelectOptions: ['Pass', 'Fail'],
    AccessorKey: 'Status',
  },
  {
    Name: 'ActualResults',
    Label: 'Actual Results',
    InputType: Textarea,
    AccessorKey: 'ActualResults',
  },
  {
    Name: 'ActionItems',
    Label: 'Action Items',
    InputType: Textarea,
    AccessorKey: 'ActionItems',
  },

]

function TestRunRecordView() {
  const navigate = useNavigate()

  const { trrId } = useParams();
  const profile = useProfileContext()

  const [canEdit, setCanEdit] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const [editing, setEditing] = useState(false);

  const { recordProp } = useLocation()?.state as { recordProp: TestRunRecord } || { recordProp: null }

  const loaderData = useLoaderData() as any

  const [record, setRecord] = useState<TestRunRecord>()
  const [trrLoading, setTrrLoading] = useState(true)
  const [preEditState, setPreEditState] = useState<FieldValues>()

  const form = useForm()

  const closeEdit = () => {
    form.reset(preEditState)
    setEditing(false)
  }

  const getTestRecord = () => {
    doRequest(`/api/v1/testrunrecords/${trrId}`, 'get')
      .then(({ data }) => {
        setRecord(data.record)
        setTrrLoading(false)
      }).catch((e) => {
        console.error(e)
        setTrrLoading(false)
      })
  }

  const saveEdit = () => {
    if (!record) return
    const body = form.getValues();

    body.RecordNumber = record.RecordNumber;
    body.DecisionRecordNumber = record.DecisionRecord.RecordNumber;

    doRequest(`/api/v1/testrunrecords/${record.RecordNumber}`, 'patch', body)
      .then(({ status }) => {
        if (status === 200) {
          getTestRecord();
          setEditing(false);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const doDelete = () => {
    doRequest(`/api/v1/testrunrecords/${record?.RecordNumber}`, 'delete')
      .then(({ status }) => {
        if (status === 200) {
          navigate('/home')
        }
      }).catch((e) => {
        console.error(e)
      })
  }

  useEffect(() => {
    if (record != null) return

    if (loaderData && loaderData.status === 200) {
      setRecord(loaderData.data.record)
      setTrrLoading(false)
      return
    }

    getTestRecord()
  }, [])

  useEffect(() => {
    if (record) {
      const values: any = {}
      formEntries.forEach((e) => {
        values[e.Name] = record[e.AccessorKey]
      })
      values.Date = record.Date
      setPreEditState(values)
      form.reset(values)
    }
  }, [record])

  // Check role for current user to see if they can edit
  useEffect(() => {
    if (record != null && profile != null) {
      if (record.CreatedBy.toLowerCase() === profile.Email.toLowerCase()) {
        setIsOwner(true);
        return;
      }

      record.DecisionRecord.Editors.forEach((e) => {
        if (e.Email === profile.Email) {
          setCanEdit(true);
          return;
        }

        if (e.LDAP.toUpperCase() === profile.LDAP.toUpperCase()) {
          setCanEdit(true);
        }
      });

      record.DecisionRecord.Testers.forEach((e) => {
        if (e.Email === profile.Email) {
          setCanEdit(true);
          return;
        }

        if (e.LDAP.toUpperCase() === profile.LDAP.toUpperCase()) {
          setCanEdit(true);
        }
      });
    }
  }, [record]);

  return (
    <div className="max-w-screen-2xl m-auto">
      { record != null && !trrLoading
        ? (
          <div className="flex justify-center max-w-8xl items-start gap-4 flex-wrap">
            <Form {...form}>
              <div className="flex-1 max-w-screen-xl flex gap-4 flex-col">
                <Card className="w-full">
                  <Card.Header className="flex justify-between flex-row items-start gap-4">
                    <div className="flex-1">
                      <Card.Title>
                        {record.DecisionRecord.TestName}
                      </Card.Title>
                      {/* <Card.Description>{record.LastExecutionDate.toLocaleString()}</Card.Description> */}
                    </div>
                    <div className="flex gap-4 m-0">
                      {editing ? (
                        <div>
                          <Button className="hover:bg-red-600 mr-2" onClick={() => closeEdit()}>
                            <i className="icon_close text-lg mr-2" />
                            Cancel
                          </Button>
                          <Button className="hover:bg-green-600" onClick={() => saveEdit()}>
                            <i className="icon_check text-lg mr-2" />
                            Done
                          </Button>
                        </div>
                      ) : null}
                      {!editing && (canEdit || isOwner) ? (
                        <Dialog>
                          <DropdownMenu>
                            <DropdownMenu.Trigger><i className="icon_more-horizontal text-2xl" /></DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                              <DropdownMenu.Item onClick={() => setEditing(!editing)}>Edit</DropdownMenu.Item>
                              <Dialog.Trigger className="w-full">
                                <DropdownMenu.Item>Delete</DropdownMenu.Item>
                              </Dialog.Trigger>
                            </DropdownMenu.Content>
                          </DropdownMenu>
                          <Dialog.Content>
                            <Dialog.Header>
                              <Dialog.Title>Are you absolutely sure?</Dialog.Title>
                              <Dialog.Description>
                                Are you sure you want to delete this Test Run Record? This cannot be undone.
                              </Dialog.Description>
                            </Dialog.Header>
                            <Dialog.Footer>
                              <Dialog.Close asChild>
                                <Button>Close</Button>
                              </Dialog.Close>
                              <Button className="bg-destructive hover:bg-destructive" onClick={() => doDelete()}>Delete</Button>
                            </Dialog.Footer>
                          </Dialog.Content>
                        </Dialog>
                      ) : null}
                    </div>
                  </Card.Header>
                  <Card.Content>
                    <div className="flex flex-col gap-4">
                      <div className="flex">
                        <div className="font-bold pt-2 w-1/3">Test Thesis</div>
                        <div className="p-2 bg-background rounded-sm border w-2/3 cursor-not-allowed">{record.DecisionRecord.Thesis}</div>
                      </div>
                      <div className="flex">
                        <div className="font-bold pt-2 w-1/3">Test Description</div>
                        <div className="p-2 bg-background rounded-sm border w-2/3 cursor-not-allowed">{record.DecisionRecord.TestDescription}</div>
                      </div>
                      <div className="flex">
                        <div className="font-bold pt-2 w-1/3">Estimated Duration</div>
                        <div className="p-2 bg-background rounded-sm border w-2/3 cursor-not-allowed">{record.DecisionRecord.EstimatedDuration || ' '}</div>
                      </div>
                      <div className="flex">
                        <div className="font-bold pt-2 w-1/3">Impacted Services</div>
                        <div className="p-2 bg-background rounded-sm border w-2/3 cursor-not-allowed">{record.DecisionRecord.ImpactedServices}</div>
                      </div>
                      <div className="flex">
                        <div className="font-bold pt-2 w-1/3">Identified Risks & Mitigation</div>
                        <div className="p-2 bg-background rounded-sm border w-2/3">{record.DecisionRecord.IdentifiedRisksAndMitigation}</div>
                      </div>
                    </div>
                    <hr className="my-4" />
                    {formEntries.map((e) => (
                      <Form.Field
                        control={form.control}
                        name={e.Name}
                        disabled={!editing}
                        render={({ field }) => (
                          <>
                            <Form.Item className="flex justify-between align-baseline">
                              <Form.Label className="w-1/3 mt-4 font-bold">{e.Label}</Form.Label>
                              <Form.Control>
                                { e.InputType !== Select
                                  ? (
                                    <e.InputType
                                      {...field}
                                      editing={editing}
                                      className="w-2/3"
                                    />
                                  )
                                  : (
                                    <Select
                                      onValueChange={(v) => {
                                        field.onChange(v)
                                      }}
                                      defaultValue={field.value}
                                      disabled={!editing}
                                    >
                                      <Select.Trigger className={`w-2/3 ${editing ? 'bg-card border-foreground' : 'text-foreground'}`}>
                                        <Select.Value placeholder={record[e.AccessorKey][0].toUpperCase() + record[e.AccessorKey].slice(1)} />
                                      </Select.Trigger>
                                      <Select.Content>
                                        {e.SelectOptions?.map((i) => (
                                          <Select.Item key={i} value={i}>{i[0].toUpperCase() + i.slice(1)}</Select.Item>
                                        ))}
                                      </Select.Content>
                                    </Select>
                                  )}
                              </Form.Control>
                            </Form.Item>
                            <Form.Message />
                          </>
                        )}
                      />
                    ))}
                    <hr className="mt-4" />
                    <AttachmentsView recordNumber={record.RecordNumber} canEdit />
                  </Card.Content>
                </Card>
              </div>
              <Sidebar className="">
                <SidebarSection open title="Test Information">
                  <SidebarSectionRow header="Record Number">
                    {record.RecordNumber}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Test Record Creator">
                    {record.CreatedBy}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Decision Record">
                    <NavLink to={`/decisionrecords/${record.DecisionRecord.RecordNumber}`}>
                      {record.DecisionRecord.RecordNumber}
                    </NavLink>
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Executed Date">
                    {
                      !editing
                        ? new Intl.DateTimeFormat('en-US', {
                          dateStyle: 'long',
                          timeZone: 'America/New_York',
                        }).format(new Date(record.DecisionRecord.LastExecutionDate))
                        : (
                          <Form.Field
                            control={form.control}
                            name="Date"
                            rules={{ required: 'Execution Date is required' }}
                            render={({ field }) => (
                              <Form.Item className="flex justify-between align-baseline">
                                <Form.Control>
                                  <Popover>
                                    <Popover.Trigger asChild>
                                      <Button
                                        variant="outline"
                                        className={cn(
                                          'w-52 justify-start text-left font-normal',
                                          !field.value && 'text-muted-foreground',
                                          editing && 'bg-card border-foreground',
                                        )}
                                      >
                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                        {field.value ? format(field.value, 'PPP') : <span>Pick a date</span>}
                                      </Button>
                                    </Popover.Trigger>
                                    <Popover.Content className="w-auto p-0">
                                      <Calendar
                                        mode="single"
                                        selected={field.value}
                                        onSelect={field.onChange}
                                        initialFocus
                                      />
                                    </Popover.Content>
                                  </Popover>
                                </Form.Control>
                              </Form.Item>
                            )}
                          />
                        )
                    }
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Record Created">
                    {new Intl.DateTimeFormat('en-US', {
                      dateStyle: 'long',
                      timeStyle: 'long',
                      timeZone: 'America/New_York',
                    }).format(new Date(record.CreatedAt))}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Last Updated">
                    {new Intl.DateTimeFormat('en-US', {
                      dateStyle: 'long',
                      timeStyle: 'long',
                      timeZone: 'America/New_York',
                    }).format(new Date(record.UpdatedAt))}
                  </SidebarSectionRow>
                </SidebarSection>
                <SidebarSection open title="Application">
                  <SidebarSectionRow header="Name">
                    <NavLink to={`/applications/${record.DecisionRecord.Application.SysId}`}>
                      {record.DecisionRecord.Application.Name}
                    </NavLink>
                  </SidebarSectionRow>
                  <SidebarSectionRow header="PSRB ID">
                    {record.DecisionRecord.Application.ParentBusinessApp.PSRBSolutionId}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Environment">
                    {record.DecisionRecord.Application.ParentBusinessApp.LifecyclePhase}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Tier">
                    {record.DecisionRecord.Application.SLATier}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Subject Matter Expert">
                    {record.DecisionRecord.Application.ParentBusinessApp.SubjectMatterExpert.Name}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Manager">
                    {record.DecisionRecord.Application.ParentBusinessApp.Manager.Name}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="Director">
                    {record.DecisionRecord.Application.ParentBusinessApp.Director.Name}
                  </SidebarSectionRow>
                  <SidebarSectionRow header="VP">
                    {record.DecisionRecord.Application.ParentBusinessApp.VP.Name}
                  </SidebarSectionRow>
                </SidebarSection>
                <SidebarSection open title="Roles">
                  <SidebarSectionRow header="Testers">
                    <UserSelector
                      showManagement={false}
                      users={record.DecisionRecord.Testers || []}
                      addUserCallback={() => {}}
                      removeUserCallback={() => {}}
                    />
                  </SidebarSectionRow>
                </SidebarSection>
              </Sidebar>
            </Form>
          </div>
        )
        : null}
      {trrLoading && <Loading />}
      {record == null && !trrLoading && <NotFound />}
      <div />
    </div>
  )
}

export default TestRunRecordView;
