import React, { useEffect, useState } from 'react';
import { TestRunRecord } from './TestRunRecordType';
import { Button } from '../../ui/button';
import { NavLink } from 'react-router-dom';
import DataTableColumnHeader from '../../ui/dataTable/dataTableColumnHeader';
import { doRequest } from '../../Utils/utils';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Input } from '../../ui/input';
import DataTableColumnVisibilityList from '../../ui/dataTable/dataTableColumnVisibilityList';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table';
import DataTablePagination from '../../ui/dataTable/datatablePagination';
import { cn } from '../../lib/utils';
import { Badge } from '../../ui/badge';
import TestRunRecordsDataTableFilters from './components/TestRunRecordsDataTableFilters';
import useNotification from "../../Common/Hooks/useNotification";


type Props = {
  data: TestRunRecord[];
  hiddenColumns?: string[];
};

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

// keep accessor key for any columns that should use searching/filtering
const cols: ColumnDef<TestRunRecord>[] = [
  {
    id: 'Number',
    accessorKey: 'RecordNumber',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Number" />,
    enableSorting: true,
    sortingFn: 'alphanumeric',
    cell: (cell) => {
      const value = cell.row.original.RecordNumber;
      return (
        <Button variant="link" size="sm" className="p-0">
          <NavLink to={`/testrecords/${value}`} target="_blank">
            {value}
          </NavLink>
        </Button>
      );
    },
  },
  {
    id: 'Execution Date',
    accessorKey: 'Date',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Execution Date" />,
    cell: (cell) => {
      const value = cell.row.original.Date;

      if (!value) {
        return 'N/A';
      }
      const led = dayjs(value);

      return <>{led.format(dateFormat)}</>;
    },
  },
  {
    id: 'Decision Record - Test Name',
    accessorKey: 'DecisionRecord.TestName',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Decision Record - Test Name" searchable />,
    cell: (cell) => {
      const value = cell.row.original.DecisionRecord.TestName;

      if (!value) {
        return 'N/A';
      }

      return (
        <Button variant="link" size="sm" className="p-0">
          <NavLink
            className="text-xs"
            to={`/testrecords/${cell.row.original.DecisionRecord.RecordNumber}`}
            target="_blank"
          >
            {value}
          </NavLink>
        </Button>
      );
    },
  },
  {
    id: 'Decision Record - Application Service',
    accessorKey: 'DecisionRecord.ApplicationService.Name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Decision Record - Application" searchable />,
    cell: (cell) => {
      const value = cell.row.original.DecisionRecord.Application.Name;

      if (!value) {
        return 'N/A';
      } else {
        return value;
      }
    },
  },
  {
    id: 'Status',
    accessorKey: 'Status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: (cell) => {
      const status = cell.row.original.Status;
      if (!status) {
        return 'N/A';
      }

      return (
        <Badge
          variant={
            status.toLowerCase() === 'pass'
              ? 'constructive'
              : status.toLowerCase() === 'fail'
                ? 'destructive'
                : 'default'
          }
          className="capitalize"
        >
          {status}
        </Badge>
      );
    },
    filterFn: 'arrIncludesSome',
  },
];

function TestRunRecordsDataTable({ data, hiddenColumns }: Props) {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'Number',
      desc: true,
    },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const notifications = useNotification();

  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setDate(today.getDate() - 30);

  const formatDate = (date) => {
    return date.toISOString().slice(0, 10);
  }
  const [fullDate, setFullDate] = useState({ start: formatDate(oneMonthAgo), end: formatDate(today), userSelected: false })
  const [dateRecords, setDateRecords] = useState<TestRunRecord[]>([]);

  const handleDateChange = (fullDate) => {
    fullDate.start = formatDate(fullDate.start)
    fullDate.end = formatDate(fullDate.end)

    setFullDate(fullDate);
  }

  const dateSortRecords = () => {
    doRequest(`/api/v1/testrunrecords`, 'get', null, {
      startDate: fullDate.start,
      endDate: fullDate.end
    }).then((data) => {
      setDateRecords(data.data.records);
    }).catch((err) => {
      notifications.addToast({ type: 'error', message: err });
    });
  }

  data = dateRecords

  const table = useReactTable({
    data: data,
    columns: cols,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    // onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      // rowSelection,
    },
  });

  useEffect(() => {
    const visibleColumns: VisibilityState = {};
    hiddenColumns?.forEach((column) => {
      visibleColumns[column] = false;
    });
    setColumnVisibility(visibleColumns);
    if (fullDate.end) { dateSortRecords() }
  }, [fullDate]);

  const filterPortalRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className="w-full">
      <div className="mb-4 flex flex-col gap-1">
        <div className="flex flex-row gap-2">
          <Input
            placeholder="Search all fields..."
            value={(table.getState().globalFilter as string) ?? ''}
            onChange={(event) => table.setGlobalFilter(event.target.value)}
            className="mr-4 h-8 max-w-sm"
          />
          <TestRunRecordsDataTableFilters table={table} portalRef={filterPortalRef} onDateChange={handleDateChange} dateRange={fullDate} />
          <DataTableColumnVisibilityList table={table} className="ml-auto h-8" />
        </div>
        <div className="flex w-full flex-col flex-wrap gap-1" ref={filterPortalRef} />
      </div>
      <div className="rounded-md border border-border">
        <Table className="border-none">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="last:border-r-0">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={cols.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} defaultPageSize={10} pageSizes={[5, 10, 15]} showFullPagination />
    </div>
  );
}

export default TestRunRecordsDataTable;
