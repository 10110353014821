import { Table } from '@tanstack/react-table';
import React from 'react';
import DataTableDropdownSelectedFilters from '../../../ui/dataTable/dataTableDropdownSelectedFilters';
import { createPortal } from 'react-dom';
import DataTableSearchSelectedFilters from '../../../ui/dataTable/dataTableSearchSelectedFilters';
import DropdownMenu from '../../../ui/dropdownMenu';
import { Button } from '../../../ui/button';
import { AppWindowIcon, CheckIcon, ContainerIcon, FlaskConicalIcon, PlusIcon, UserIcon, CalendarRange } from 'lucide-react';
import DataTableDropdownFilter from '../../../ui/dataTable/dataTableDropdownFilter';
import DataTableSearchFilter from '../../../ui/dataTable/dataTableSearchFilter';
import { TestRunRecord } from '../TestRunRecordType';
import DataTableCalendarFilter from '../../../ui/dataTable/dataTableCalendarFilter';
import DataTableCalendarSelectedFilter from '../../../ui/dataTable/dataTableCalendarSelectedFilter';

type TestRunRecordsDataTableFilters = {
  table: Table<TestRunRecord>;
  portalRef: React.RefObject<HTMLDivElement> | null;
  onDateChange: Function;
  dateRange:any;
};

function TestRunRecordsDataTableFilters({ portalRef, table, onDateChange, dateRange }: TestRunRecordsDataTableFilters) {
  const statusColumn = table.getColumn('Status');
  const testNameColumn = table.getColumn('Decision Record - Test Name');
  const application = table.getColumn('Decision Record - Application Service');
  const executionDateColumn = table.getColumn('Execution Date');

  return (
    <>
      {portalRef?.current &&
        createPortal(
          <>
            {statusColumn && <DataTableDropdownSelectedFilters column={statusColumn} title="Statuses" />}
            {testNameColumn && <DataTableSearchSelectedFilters column={testNameColumn} title="Test Name" />}
            {application && <DataTableSearchSelectedFilters column={application} title="Application" />}
            {<DataTableCalendarSelectedFilter title="Execution Date" dateRange={dateRange} onDateChange={onDateChange} />}
          </>,
          portalRef.current
        )}
      {/* {managerColumn && (
          <DataTableDropdownFilter table={table} column={managerColumn} title="Managers" Icon={UserIcon} />
        )} */}
      <DropdownMenu>
        <DropdownMenu.Trigger className="m-0" asChild>
          <Button variant="outline" className="h-8 gap-2">
            <p>Filters</p>
            <PlusIcon className="h-4 w-4 text-primary" />
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="w-56">
          <DropdownMenu.Label>Users</DropdownMenu.Label>
          <DropdownMenu.Group>
            {statusColumn && (
              <DataTableDropdownFilter
                table={table}
                column={statusColumn}
                title="Statuses"
                isSubMenu
                Icon={CheckIcon}
              />
            )}
          </DropdownMenu.Group>
          <DropdownMenu.Separator />
          <DropdownMenu.Label>Searches</DropdownMenu.Label>
          <DropdownMenu.Group>
            {testNameColumn && (
              <DataTableSearchFilter column={testNameColumn} title="Test Names" Icon={FlaskConicalIcon} isSubMenu />
            )}
            {application && (
              <DataTableSearchFilter column={application} title="Applications" Icon={AppWindowIcon} isSubMenu />
            )}
            {executionDateColumn && (
              <DataTableCalendarFilter title="Execution Date" onDateChange={onDateChange} Icon={CalendarRange} dateRange={dateRange}/>
            )}
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu>
    </>
  );
}

export default TestRunRecordsDataTableFilters;
