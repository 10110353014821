/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLoaderData, useLocation, useParams } from 'react-router-dom';
import Loading from 'react-loading';
import { Legend, PieChart, Tooltip as RechartsTooltip, Pie, Cell } from 'recharts';
import { CSVLink } from 'react-csv';
import Card from '../ui/card';
import { ApplicationService } from './ApplicationType';
import { doRequest } from '../Utils/utils';
import { AppPageColumns, DecisionRecord } from '../Records/DecisionRecords/DecisionRecordType';
import DataTable from '../ui/dataTable/datatable';
import { Button } from '../ui/button';
import NotFound from '../SharedComponents/NotFound';
import Accordion from '../ui/accordion';

type ChartData = {
  name: string;
  value: number;
}

const PIE_CHART_COLORS = [
  'green',
  'hsl(var(--destructive))',
  'hsl(var(--inactive))',
];

function ApplicationSingleView() {
  const { appParam } = useParams();

  const { applicationProp } = useLocation()?.state as { applicationProp: ApplicationService } || { applicationProp: null }

  const loaderData = useLoaderData() as any

  const [application, setApplication] = useState<ApplicationService>()
  const [appLoading, setAppLoading] = useState(true)
  const [records, setRecords] = useState<DecisionRecord[]>([])
  const [chartData, setChartData] = useState<ChartData[]>([])

  useEffect(() => {
    if (applicationProp) {
      setApplication(applicationProp)
      setAppLoading(false)
      return
    }

    if (loaderData && loaderData.status === 200) {
      setAppLoading(false)
      if (loaderData.data.application.SysId !== '') setApplication(loaderData.data.application)
      return
    }

    doRequest(`/api/v1/applications/${appParam}`, 'get')
      .then(({ data }) => {
        setAppLoading(false)
        if (data.application.SysId !== '') setApplication(data.application)
      }).catch((e) => {
        console.error(e)
        setAppLoading(false)
      })
  }, [])

  useEffect(() => {
    if (application) {
      doRequest(`/api/v1/applications/${application?.SysId}/decisionrecords`, 'get')
        .then(({ data }) => {
          setRecords(data.records)
        })
    }
  }, [application])

  useEffect(() => {
    let passing = 0;
    let failing = 0;
    let notRun = 0;
    records.forEach((record) => {
      if (!record.LastTest) {
        notRun += 1
      } else if (record.LastTest.Status === 'pass') {
        passing += 1
      } else {
        failing += 1
      }
    })

    setChartData([
      { name: 'Passing', value: passing },
      { name: 'Failing', value: failing },
      { name: 'Not Run', value: notRun },
    ])
  }, [records])

  const getCSVData = () => {
    if (!records || !application) return []

    return records.map((record) => ({
      Manager: application.ParentBusinessApp.Manager.Name,
      VP: application.ParentBusinessApp.VP.Name,
      Director: application.ParentBusinessApp.Director.Name,
      SeniorManager: application.ParentBusinessApp.SeniorManager.Name,
      SubjectMatterExpert: application.ParentBusinessApp.SubjectMatterExpert.Name,
      Env: application.ParentBusinessApp.LifecyclePhase,
      PSRBId: application.ParentBusinessApp.PSRBSolutionId,
      ApplicationName: application.ParentBusinessApp.Name,
      SLATier: application.SLATier,
      DecisionRecord: record.RecordNumber,
      LastExecutionDate: record.LastExecutionDate,
      TestsRun: record.Counts.TestRunCount,
      TestsPassed: record.Counts.SuccessCount,
      TestsFailed: record.Counts.FailCount,
    }));
  }

  return (
    <>
      { application != null && !appLoading
        ? (
          <div style={{
            maxWidth: '1400px',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '30px',
          }}
          >
            <Card style={{
              width: '100%',
            }}
            >
              <Card.Header>
                <Card.Title>{application?.Name}</Card.Title>
              </Card.Header>
              <Card.Content className="flex flex-wrap gap-y-2">
                <div className="flex items-center w-full">
                  <div className="w-2/3 border-r-2 pr-4 flex flex-wrap gap-y-2">
                    <div className="flex gap-4 w-full">
                      <div className="font-bold min-w-40">PSRB ID:</div>
                      <div>{application?.ParentBusinessApp?.PSRBSolutionId}</div>
                    </div>
                    <div className="flex gap-4 w-full">
                      <div className="font-bold min-w-40">Short Description:</div>
                      <div>{application?.ParentBusinessApp.ShortDescription}</div>
                    </div>
                    <div className="flex gap-4 w-1/2">
                      <div className="font-bold min-w-40">Environment:</div>
                      <div>{application?.ParentBusinessApp.LifecyclePhase}</div>
                    </div>
                    <div className="flex gap-4 w-1/2">
                      <div className="font-bold min-w-40">SLA Tier:</div>
                      <div>{application?.SLATier}</div>
                    </div>
                    <div className="flex gap-4 w-1/2">
                      <div className="font-bold min-w-40">Subject Matter Expert:</div>
                      <div>{application?.ParentBusinessApp.SubjectMatterExpert?.Name || ''}</div>
                    </div>
                    <div className="flex gap-4 w-1/2">
                      <div className="font-bold min-w-40">Manager:</div>
                      <NavLink to={`/reporting/manager/${application.ParentBusinessApp.Manager.UserId}`}>
                        <div>{application?.ParentBusinessApp.Manager?.Name || ''}</div>
                      </NavLink>
                    </div>
                    <div className="flex gap-4 w-1/2">
                      <div className="font-bold min-w-40">Sr Manager:</div>
                      <div>{application?.ParentBusinessApp.SeniorManager?.Name || ''}</div>
                    </div>
                    <div className="flex gap-4 w-1/2">
                      <div className="font-bold min-w-40">Director</div>
                      <NavLink to={`/reporting/director/${application.ParentBusinessApp.Director.UserId}`}>
                        <div>{application?.ParentBusinessApp.Director?.Name || ''}</div>
                      </NavLink>
                    </div>
                    <div className="flex gap-4 w-1/2">
                      <div className="font-bold min-w-40">Business Owner:</div>
                      <NavLink to={`/reporting/businessowner/${application.ParentBusinessApp.BusinessOwner.UserId}`}>
                        <div>{application?.ParentBusinessApp.BusinessOwner?.Name || ''}</div>
                      </NavLink>
                    </div>
                    <div className="flex gap-4 w-1/2">
                      <div className="font-bold min-w-40">VP:</div>
                      <NavLink to={`/reporting/vp/${application.ParentBusinessApp.VP.UserId}`}>
                        <div>{application?.ParentBusinessApp.VP?.Name || ''}</div>
                      </NavLink>
                    </div>
                    <div className="w-full bg-background mt-2">
                      <Accordion type="single" collapsible className="w-full">
                        <Accordion.Item value="item-1">
                          <Accordion.Trigger className="p-4">
                            <h3 className="font-bold">Where is this data being pulled from?</h3>
                          </Accordion.Trigger>
                          <Accordion.Content className="flex flex-col gap-4 p-4 pt-2">
                            <p>
                              The above fields are pulled from the CMDB table in Service Now. The Senior Manager above is set as the Operational Manager listed in CMDB (if they are a Sr. Manager, if they are not a Sr. Manager, their manager is selected). If no Operational Manager is present in the CMDB, the Senior Manager is derived from the listed Manager.
                              <a
                                className="ml-1"
                                target="_blank"
                                rel="noreferrer"
                                href={`https://homedepot.service-now.com/now/nav/ui/classic/params/target/cmdb_ci_service_auto.do%3Fsys_id%3D${application.SysId}%26sysparm_view%3DDefault%2Bview%26sysparm_view_forced%3Dtrue`}
                              >
                                Click here to view the table information in full.
                              </a>
                            </p>
                            <p>
                              If this information is incorrect, please <a target="_blank" rel="noreferrer" className="mx-1" href="https://homedepot.service-now.com/thd?id=sc_cat_item&sys_id=2a0c2acfdb59a700dbbd2a25ca96194c&sysparm_category=13c84af46fc2f500efa37301be3ee461">click here</a> to submit a ticket to the CMDB team. Once updated, changes will be reflected here.
                            </p>
                          </Accordion.Content>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                  <PieChart width={300} height={180} style={{ outline: 'none' }}>
                    <Legend layout="vertical" verticalAlign="middle" align="right" />
                    <RechartsTooltip />
                    <Pie
                      style={{ cursor: 'pointer', outline: 'none' }}
                      dataKey="value"
                      nameKey="name"
                      data={chartData}
                      cx="50%"
                      cy="50%"
                      outerRadius="80%"
                      stroke="none"
                      animationBegin={0}
                      animationDuration={500}
                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${entry.name}`} fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
              </Card.Content>
            </Card>
            <Card className="w-full">
              <Card.Header>
                <div className="flex justify-between items-middle">
                  <Card.Title>Decision Records</Card.Title>
                  <div className="flex justify-center gap-8">
                    <CSVLink data={getCSVData()} filename={`${application.Name}-decision-records.csv`}>
                      <Button className="text-sm px-4 py-2">
                        Export to CSV
                      </Button>
                    </CSVLink>
                    <Link
                      to="/decisionrecords/new"
                      state={{
                        applicationParam: application,
                      }}
                    >
                      <Button>
                        Create New Decision Record
                      </Button>
                    </Link>
                  </div>
                </div>
              </Card.Header>
              <Card.Content>
                <DataTable data={records} columns={AppPageColumns} />
              </Card.Content>
            </Card>
          </div>
        )
        : null}
      {appLoading && <Loading />}
      {application == null && !appLoading && <NotFound />}
      <div />
    </>
  )
}

export default ApplicationSingleView;
