import React, { useState } from 'react';
import './sidebar.css';

function SidebarSection(props) {
  const { open, title, children } = props;

  const [isOpen, setIsOpen] = useState(open);

  return (
    <div className="sidebar-section bg-card border shadow-sm">
      <div className="accordion">
        <div
          style={{ cursor: 'pointer', borderBottom: isOpen ? '1px solid rgb(220, 220, 220)' : 'none', padding: '10px 20px' }}
          className="accordion-header"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex justify-between items-center w-full">
            <header>{title}</header>
            <i className={!isOpen ? 'icon_chevron_down chevron closed' : 'icon_chevron_down chevron open'} />
          </div>
        </div>
        <div style={{ padding: 0, marginBottom: isOpen ? 2 : 0, overflowY: isOpen ? 'visible' : 'hidden' }} className={`accordion-content ${isOpen ? 'open' : 'closed'}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default SidebarSection;
