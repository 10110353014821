import { Table } from '@tanstack/react-table';
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';
import { useEffect, useState } from 'react';
import Select from '../select';
import { Button } from '../button';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  pageSizes?: number[];
  defaultPageSize?: number;
  showFullPagination?: boolean;
}

export default function DataTablePagination<TData>({
  table,
  pageSizes = [10, 20, 30, 40, 50],
  defaultPageSize,
  showFullPagination = false,
}: DataTablePaginationProps<TData>) {
  const [pageSize, setPageSize] = useState(defaultPageSize || table.getState().pagination.pageSize);

  useEffect(() => {
    if (defaultPageSize) table.setPageSize(defaultPageSize);
  }, []);

  useEffect(() => {
    setPageSize(table.getState().pagination.pageSize);
  }, [table.getState().pagination.pageSize]);

  return (
    <div className="flex items-center justify-between px-2 pt-3">
      {table.getFilteredSelectedRowModel().rows.length === 0 ? (
        <div></div>
      ) : (
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s)
          selected.
        </div>
      )}
      <div className="flex items-center space-x-6 lg:space-x-8">
        {showFullPagination && (
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">Rows per page</p>
            <Select
              value={`${pageSize}`}
              onValueChange={(value) => {
                table.setPageSize(Number(value));
              }}
            >
              <Select.Trigger className="h-8 w-[70px]">
                <Select.Value placeholder={pageSize} />
              </Select.Trigger>
              <Select.Content side="top">
                {pageSizes.map((pageSize) => (
                  <Select.Item key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </div>
        )}
        {table.getPageCount() === 0 ? (<div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount() + 1}
        </div>
        ) : (<div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        )}
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4 text-primary" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4 text-primary" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4 text-primary" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4 text-primary" />
          </Button>
        </div>
      </div>
    </div>
  );
}
