
import React, { useState } from 'react';
import { cn } from '../../lib/utils';
import { Button } from '../button';
import { XIcon } from 'lucide-react';
import { format, parseISO} from 'date-fns';

function DataTableCalendarSelectedFilter({
    title,
    dateRange,
    onDateChange
}) {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setDate(today.getDate() - 30);

    const [fullDate, setFullDate] = useState(
        {
            start: oneMonthAgo,
            end: today,
            userSelected: false
        }
    );

    const getDate = () => {
        onDateChange(fullDate);
    };

    const clearDate = () => {
        setFullDate({ start: oneMonthAgo, end: today, userSelected: false });
        getDate();
    }

    const formattedStart = format(parseISO(dateRange.start), 'MMM dd, yyyy');
    const formattedEnd = format(parseISO(dateRange.end), 'MMM dd, yyyy');

    return (
        <>
            <div className={cn('flex flex-col gap-0.5')}>
                {<p className="text-sm font-semibold">{title}</p>}
                {dateRange?.userSelected ? <div className="flex flex-row flex-wrap gap-2">
                    <Button variant="outline" className="h-8 cursor-default gap-2" key={dateRange}>
                        {formattedStart} - {formattedEnd}
                        <XIcon
                            className="h-4 w-4 cursor-pointer rounded-sm text-primary hover:bg-primary/10"
                            onClick={clearDate}
                        />
                    </Button>
                </div> :
                    <div className="flex flex-row flex-wrap gap-2">
                        <Button variant="outline" className="h-8 cursor-default gap-2" key={dateRange}>
                            {formattedStart} - {formattedEnd}
                        </Button>

                    </div>}
            </div>

        </>
    );
}
export default DataTableCalendarSelectedFilter;
