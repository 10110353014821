// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;

  /* border: 0.5px solid #cccccc; */
}

.accordion-header {
  display: flex;
  align-items: center;
}

.chevron {
  align-items: center;
  display: flex;
  justify-self: center;
  transition: all 0.5s ease;

  color: #f96303;
  cursor: pointer;
  margin-left: -3px;
  max-height: 0;
}

.chevron.open {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 0 15px;
  /* overflow-y: hidden; */
  /* transition: all 1s ease; */
  
}

.accordion-content.open {
  max-height: 500px;
  transition: max-height .2s ease;
}

.accordion-content.closed {
  max-height: 0;
  transition: max-height 0.25s ease;
  /* padding: 0; */
}`, "",{"version":3,"sources":["webpack://./src/recker/Layout/Sidebar/accordion.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;;EAEX,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,oBAAoB;EACpB,yBAAyB;;EAEzB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,6BAA6B;;AAE/B;;AAEA;EACE,iBAAiB;EACjB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,iCAAiC;EACjC,gBAAgB;AAClB","sourcesContent":[".accordion {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n\n  /* border: 0.5px solid #cccccc; */\n}\n\n.accordion-header {\n  display: flex;\n  align-items: center;\n}\n\n.chevron {\n  align-items: center;\n  display: flex;\n  justify-self: center;\n  transition: all 0.5s ease;\n\n  color: #f96303;\n  cursor: pointer;\n  margin-left: -3px;\n  max-height: 0;\n}\n\n.chevron.open {\n  transform: rotate(180deg);\n}\n\n.accordion-content {\n  padding: 0 15px;\n  /* overflow-y: hidden; */\n  /* transition: all 1s ease; */\n  \n}\n\n.accordion-content.open {\n  max-height: 500px;\n  transition: max-height .2s ease;\n}\n\n.accordion-content.closed {\n  max-height: 0;\n  transition: max-height 0.25s ease;\n  /* padding: 0; */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
