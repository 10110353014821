import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { redirect, useLocation, useNavigate } from 'react-router-dom';

import MultistepForm, { Step } from '../../Form/multistep';
import ApplicationStep from '../FormSteps/ApplicationStep';
import PreTestingStep from '../FormSteps/PreTestingStep';
import { doRequest } from '../../Utils/utils';
import AttachmentStep from "../FormSteps/AttachmentStep";
import useAttachment from "../../Common/Hooks/useAttachment";
import { ApplicationService } from '../../Applications/ApplicationType';
import { DecisionRecord } from './DecisionRecordType';
import { useAuthContext } from '@one-thd/retooling-react';
import useNotification from '../../Common/Hooks/useNotification';

export default function NewDecisionRecord() {
  const navigate = useNavigate()
  const authContext = useAuthContext()
  const notifications = useNotification();

  const [attachments, setAttachments] = useState<File[]>([])
  const [application, setApplication] = useState<ApplicationService>()
  const [testTool, setTestTool] = useState<string>('')

  const { applicationParam, recordProp } = useLocation()?.state as { applicationParam: ApplicationService, recordProp: DecisionRecord } || { applicationParam: null, recordProp: null }

  const { upload: uploadAttachment } = useAttachment({ POST: '/api/v1/attachments/:recordNum' })

  const [currentStep, setCurrentStep] = useState(0)
  const form = useForm()

  useEffect(() => {
    if (applicationParam) {
      setApplication(applicationParam)
    }

    if (recordProp) {
      form.setValue('TestName', recordProp.TestName)
      form.setValue('TestDescription', recordProp.TestDescription)
      form.setValue('TestTool', recordProp.TestTool)
      form.setValue('Thesis', recordProp.Thesis)
      form.setValue('IdentifiedRisksAndMitigation', recordProp.IdentifiedRisksAndMitigation)
      form.setValue('ImpactedServices', recordProp.ImpactedServices)
      form.setValue('EstimatedDuration', recordProp.EstimatedDuration)
      setCurrentStep(3)
    }
  }, [])

  const steps: Step[] = [
    {
      label: 'Application',
      title: 'Application Information',
      component: useCallback((form: UseFormReturn) => <ApplicationStep form={form} application={application} onApplicationChange={setApplication} />, [application]),
    },
    {
      label: 'Testing',
      title: 'Testing Information',
      component: useCallback((form: UseFormReturn) => <PreTestingStep form={form} testTool={testTool} onTestToolChange={setTestTool} />, []),
    },
    {
      label: 'Attachments',
      title: 'Add Attachments',
      component: useCallback(() => <AttachmentStep attachments={attachments} onAttachmentsChange={setAttachments} />, [attachments]),
    },
    {
      label: 'Review',
      title: 'Review',
      component: useCallback((form: UseFormReturn) => (
        <div>
          {steps.map((step, i) => (
            <div>
              {i !== steps.length - 1
                ? (
                  <div>
                    <h3 className="text-lg mb-2">{step.title}</h3>
                    {step.component(form)}
                    {i !== steps.length - 2 ? <hr className="mt-6 mb-6" /> : null }
                  </div>
                )
                : null}
            </div>
          ))}
        </div>
      ), [attachments, application]),
    },
  ]

  const onSubmit: SubmitHandler<FieldValues> = (values) => {
    // Check if in stage. If so, block any users without the recker_admin role
    // eslint-disable-next-line dot-notation
    if (window.location.host.includes('recker.hd-engineering') || window['NODE_ENV'] === 'stage') {
      const { roles } = authContext.currentUser

      if (roles.indexOf('recker_admin') === -1) {
        notifications.addToast({ type: 'error', message: 'Non-admin users cannot create Decision Records in stage.' })
        return
      }
    }

    doRequest('/api/v1/decisionrecords', 'post', { ...values, ApplicationID: values.ApplicationSelect.SysId }, null)
      .then((data) => {
        if (data && data.status === 201 && data.data) {
          submitAttachments(data.data.record.RecordNumber)
            .then(() => {
              navigate(`/decisionrecords/${data.data.record.RecordNumber}`, {
                state: {
                  recordProp: data.data.record,
                },
              })
            })
        }
      })
      .finally(() => {
      })
  };

  const submitAttachments = async (recordNumber: string): Promise<void> => {
    const promises: Promise<void>[] = []

    attachments.forEach((attachment) => {
      promises.push(uploadAttachment({ recordNum: recordNumber }, attachment))
    })

    return Promise.all(promises)
      .then(() => Promise.resolve()).catch(() => {
        // handle error
      })
  }

  return (
    <MultistepForm title="Create New Decision Record" steps={steps} onSubmit={onSubmit} formParam={form} currentStepParam={[currentStep, setCurrentStep]} />
  )
}
